<template>
    <div>
        <v-toolbar
            dense
            tabs
            color="primary darken-2"
            dark
            class="page_title"
        >
            <v-toolbar-title class="pa-0 pr-0 mt-1">
                <v-layout row >
                    <v-flex xs10 sm11 text-xs-left>
                        <a href="javascript: history.go(-1)" class="pa-0">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-arrow-left headline"></i>
                            </v-btn>
                        </a>
                        Become a Partner
                    </v-flex>
                    <v-flex xs2 sm1 text-xs-right>
                        <router-link :to="$store.state.close_url" class="pa-0 text-xs-right">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-close headline"></i>
                            </v-btn>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <v-layout row wrap>
                                <v-flex xs12 sm6 md6 class="pa-2" @click.stop="marketer = true" @click="Dialog = true" >
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 4}`" style="border-radius: 5px; font-size: small; cursor: pointer">
                                            <v-layout row >
                                                <v-flex xs8 class="text-xs-left">
                                                    <h5 class="subheading mt-1 pl-3 pt-2 pb-0 mb-0"><b>Become Our Brand Ambassador</b></h5>
                                                </v-flex>
                                                <v-flex xs4  class="text-xs-left">
                                                    <v-btn fab color="teal" class="mt-2 ml-3 mb-0 mr-2 white--text">
                                                        <i class="fas fa-coins fa-2x"></i>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>

                                            <v-card-title primary-title class="pb-0 mt-0 pt-1" style="color: #424242">
                                                <div>
                                                    <div class="caption mt-0 mb-0 pb-2">Receive enticing <b>commissions </b> for every active new client brought onboard.</div>
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                                <v-flex xs12 sm6 md6 class="pa-2" @click.stop="investor = true" @click="Dialog = true" >
                                    <v-hover>
                                        <v-card @click="Dialog = true" slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 4}`" style="border-radius: 5px; font-size: small; cursor: pointer ">
                                            <v-layout row >
                                                <v-flex xs8 class="text-xs-left">
                                                    <h5 class="subheading mt-1 pl-3 pt-2 pb-0 mb-0"><b>Invest as a Venture Capitalist</b></h5>
                                                </v-flex>
                                                <v-flex xs4  class="text-xs-left">
                                                    <v-btn fab color="primary" class="mt-2 ml-3 mb-0 mr-2 white--text">
                                                        <i class="fas fa-money-bill fa-2x"></i>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>

                                            <v-card-title primary-title class="pb-0 mt-0 pt-1" style="color: #424242">
                                                <div>
                                                    <div class="caption mt-0 mb-0 pb-2"> Receive <b>profit percentages</b> based on investment earnings to unimaginable heights.</div>
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                            <v-card color="white" style="border-radius: 10px;">
                                <v-card-text>
                                    <p class="mb-1">Our Fintech platform is a game-changing and revolutionizing financial management.</p>
                                    <p class="mb-1">Join our <b>PettyCash</b> community and unlock untapped potential as our partners embark on a journey towards unprecedented success, earning enticing <b>commissions </b> for every new client brought onboard.</p>
                                    <p class="mb-1"><b>Profit percentages</b> based on investment soar earnings to unimaginable heights.</p> 
                                    <p class="mb-1">While being a partner, experience a financial revolution with hassle-free solutions for saving, loans, buying, selling, fundraising, and expert financial advice at their fingertips. </p>
                                    <p class="mb-1">Join us today to redefine financial empowerment and be part of the future of finance.</p> 
                                    <p class="mb-1"><b>PettyCash SAS</b> where success knows no bounds.</p>
                                </v-card-text>
                            </v-card>
                            <v-dialog width="500px" v-model="Dialog"> 
                                <v-card> 
                                    <v-card-title class="headline primary darken-2 white--text" primary-title>Confirm Partnership</v-card-title> 
                                    <v-card-text class="pa-4">
                                        <h2>Partnership Strong Points</h2>
                                        <ul v-for="point in investment_points" v-if="investor">
                                            <li> {{  point }}</li>
                                        </ul>
                                        <ul v-for="point in marketing_points" v-if="marketer">
                                            <li> {{  point }}</li>
                                        </ul>
                                        Click submit below to validated your partnership interest.
                                    </v-card-text>

                                    <v-card-actions> 
                                        <v-btn color="teal" class="white--text" @click="becomePartner()" > Submit </v-btn> 
                                        <v-spacer></v-spacer> 
                                        <v-btn color="teal" flat @click="Dialog = false" > Cancel </v-btn> 
                                    </v-card-actions> 
                                </v-card> 
                            </v-dialog> 
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios'
    import 'animate.css'

    export default {
        data() {
            return {
                Dialog: false,
                investor: false,
                marketer: false,
                marketing_points: [
                    "1. Strong understanding of marketing principles and strategies.",
                    "2. Proven track record of success in sales and marketing.",
                    "3. Excellent communication and persuasion skills.",
                    "4. Ability to build and maintain client relationships.",
                    "5. Knowledge of various marketing channels and platforms.",
                    "6. Proficiency in using digital marketing tools and analytics.",
                    "7. Results-driven mindset and ability to meet sales targets.",
                ],
                investment_points: [
                    "1. Demonstrated expertise in the relevant industry or field.",
                    "2. Proven track record of entrepreneurial success and business growth.",
                    "3. Strong leadership and decision-making skills.",
                    "4. Financial acumen and ability to analyze and interpret data.",
                    "5. Effective problem-solving and critical thinking abilities.",
                    "6. Exceptional networking and relationship-building skills.",
                    "7. Passion, drive, and commitment to the vision and goals of the business.",
                ],
            }
        },
        
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Become a Partner."
            this.$store.commit('setCloseURL', "/")
        },

        methods:{

            async becomePartner(){
                this.$store.commit('setIsLoading', true)
                this.Dialog = false
    
                axios
                    .post('/api/v1/home/become/partner/', {'investor': this.investor, 'marketer': this.marketer})
                    .then(response => {
                        this.investor = false
                        this.marketer = false
                        console.log(response.data[0])
                        this.$store.commit('setSnackBarMessage', response.data[0].text)
                        this.$store.commit('setSnackBarColor', response.data[0].color)
                        this.$store.commit('activateSnackBar', true)

                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })

                this.$store.commit('setIsLoading', false)

            },
        },
    }
</script>
